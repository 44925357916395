<template>
  <div class="container">
    <div class="my_top" ref="myTop">
      <span class="my_sqk">省钱卡</span>
    </div>
    <div class="card_view">
      <img
        :src="item.cardPic + '?t=' + Date.now()"
        class="card_card"
        alt=""
        v-for="item in cardInfo"
        :key="item.id"
        @click="buyCard(item)"
      />
      <div style="margin-bottom: 40px">
        <div class="card_title">规则说明</div>
        <div class="card_txt">
          <div class="card_text">1、1糖豆=1元人民币，糖豆领取后无使用时间限制，糖豆是乐糖游戏盒子的虚拟交易货币，仅限在乐糖盒子内的游戏使用。</div>
          <div class="card_text">2、月卡有效期为30天，开卡当天可领150糖豆，每天可领5糖豆；季卡有效期为90天，开卡当天可领900糖豆，每天可领10糖豆；年卡有效期为365天，开卡当天可领3650糖豆，每天可领20糖豆</div>
          <div class="card_text">3、此活动所得到的糖豆适用于乐糖游戏平台的所有游戏内充值金额抵扣</div>
          <div class="card_text">4、每天返还的糖豆仅限当天领取，当天未领取则失效</div>
          <div class="card_text">5、月卡、季卡、年卡都有专属的高级标志</div>
          <div class="card_text">6、可同时购买月卡、季卡、年卡，享受糖豆叠加</div>
          <div class="card_text">特别提醒：乐糖平台的“月卡，季卡，年卡”为优惠卡券产品，售出不予退款，请知悉；本产品最终解释权归乐糖游戏所有</div>
        </div>
      </div>
    </div>
    <van-action-sheet
      v-model="buymenu"
      title="支付金额"
      safe-area-inset-bottom
    >
      <div class="my_content">
        <div class="card_top">
          <div class="card_t_center">
            <span class="card_fuhao">￥</span>
            <span class="card_je">{{currentMoney}}</span>
          </div>
        </div>
        <div class="card_center">
          <div class="card_fs">支付方式</div>
          <div class="card_select">
            <van-radio-group v-model="radio">
              <van-cell-group>
                <van-cell title="支付宝(推荐)" :icon="alipay" clickable @click="radio = 'blue'">
                  <template #right-icon>
                    <van-radio name="blue"/>
                  </template>
                </van-cell>
                <van-cell title="微信" :icon="wpay" clickable @click="radio = 'green'">
                  <template #right-icon>
                    <van-radio name="green"/>
                  </template>
                </van-cell>
              </van-cell-group>
            </van-radio-group>
          </div>
        </div>
        <div class="page_pay" @click="launchPay">立即支付</div>
      </div>
    </van-action-sheet>
    <!-- 登录 -->
    <van-action-sheet
      v-model="admin.login"
      title="用户登录"
      safe-area-inset-bottom
    >
      <div class="my_content">
        <van-field
          class="my_field"
          label="用户名"
          v-model="form.username"
          placeholder="用户名/手机号"
        />
        <van-field
          class="my_field"
          label="密码"
          :type="ps.s1"
          @click-right-icon="clickIcon('s1')"
          right-icon="closed-eye"
          v-model="form.password"
          placeholder="密码6-12位字母或数字组合"
        />
        <div class="my_saveps">
          <van-checkbox v-model="checked" icon-size="16px">记住密码</van-checkbox>
        </div>
        <div class="my_save_btn my_login_btn" @click="clickHandle('login')">
          立即登录
        </div>
        <div class="my_other">
          <span @click="onclick('retrieve')">找回密码</span>
          <span @click="onclick('register')">新用户注册</span>
        </div>
      </div>
    </van-action-sheet>
    <!-- 手机注册 -->
    <van-action-sheet
      v-model="admin.register"
      title="手机号注册"
      safe-area-inset-bottom
    >
      <div class="my_content">
        <van-field
          class="my_field"
          label="手机号"
          v-model="form.phone"
          placeholder="输入手机号"
        />
        <div class="my_code">
          <van-field
            class="my_field"
            label="验证码"
            v-model="form.code"
            placeholder="验证码"
          />
          <div
            class="my_getcode"
            @click="clickHandle('getcode', 'c4')"
            ref="c4"
          >
            获取验证码
          </div>
        </div>
        <div class="my_saveps">
          <van-checkbox v-model="checked" icon-size="16px">记住密码</van-checkbox>
        </div>
        <div
          class="my_save_btn my_login_btn"
          @click="clickHandle('phoneregister')"
        >
          注册并登录
        </div>
        <div class="my_other">
          <span></span>
          <span @click="onclick('newuser')">用户名注册</span>
        </div>
      </div>
    </van-action-sheet>
    <!-- 用户名注册 -->
    <van-action-sheet
      v-model="admin.newuser"
      title="用户名注册"
      safe-area-inset-bottom
    >
      <div class="my_content">
        <van-field
          class="my_field"
          label="用户名"
          v-model="form.username"
          placeholder="用户名6-10位字母或数字"
        />
        <van-field
          class="my_field"
          label="密码"
          :type="ps.s4"
          @click-right-icon="clickIcon('s4')"
          right-icon="closed-eye"
          v-model="form.password"
          placeholder="密码6-12位字母或数字组合"
        />
        <div class="my_saveps">
          <van-checkbox v-model="checked" icon-size="16px">记住密码</van-checkbox>
        </div>
        <div
          class="my_save_btn my_login_btn"
          @click="clickHandle('usernameregister')"
        >
          注册并登录
        </div>
      </div>
    </van-action-sheet>
    <!-- 找回密码 -->
    <van-action-sheet
      v-model="admin.retrieve"
      title="找回密码"
      safe-area-inset-bottom
    >
      <div class="my_content">
        <van-field
          class="my_field"
          label="手机号"
          v-model="form.phone"
          placeholder="输入手机号"
        />
        <div class="my_code">
          <van-field
            class="my_field"
            label="验证码"
            v-model="form.code"
            placeholder="验证码"
          />
          <div
            class="my_getcode"
            @click="clickHandle('getcode', 'c3')"
            ref="c3"
          >
            获取验证码
          </div>
        </div>
        <van-field
          class="my_field"
          label="新密码"
          :type="ps.s2"
          @click-right-icon="clickIcon('s2')"
          right-icon="closed-eye"
          v-model="form.password"
          placeholder="密码6-12位字母或数字组合"
        />
        <van-field
          class="my_field"
          label="确认密码"
          :type="ps.s3"
          @click-right-icon="clickIcon('s3')"
          right-icon="closed-eye"
          v-model="form.confirmpassword"
          placeholder="密码6-12位字母或数字组合"
        />
        <div
          class="my_save_btn my_login_btn"
          @click="clickHandle('findpassword')"
        >
          重置密码
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";
import { UPDATE_USER } from "@/store";
import { ksort } from "@/utils/ksort";
import { askBuyLeCoin, askLeCoin, askLogin, askGetCode, askFindPass, askPhoneRegister, askUserNameRegister, askGiftlist } from "@/api/index";
import { Dialog, Toast } from "vant";
import AliPayImage from '@/assets/alipay.png'
import WechatPayImage from '@/assets/wechatpay.png'
import { Encrypt, Decrypt } from "@/utils/index";

export default {
  data() {
    return {
      cardInfo: [],
      buymenu: false,
      wpay: WechatPayImage,
      alipay: AliPayImage,
      radio: 'blue',
      currentMoney: 0,
      params: {},
      // 登录模块
      form: {
        username: "", // 用户名
        password: "", // 旧密码
        newpassword: "", // 新密码
        confirmpassword: "", // 确认密码
        phone: "", // 手机号
        code: "", // 验证码
        name: "", // 姓名
        idcard: "", // 身份证号
      },
      admin: {
        login: false,
        register: false,
        retrieve: false,
        username: false,
        password: false,
        newuser: false,
        bind: false,
        unbind: false,
        auth: false,
        agreement: false,
        permit: false,
        statement: false,
        privacy: false,
        protect: false,
      },
      ps: {
        s1: "password",
        s2: "password",
        s3: "password",
        s4: "password",
        s5: "password",
        s6: "password",
        s7: "password",
      },
      checked: true,
    };
  },
  beforeRouteEnter(to,from,next) {
    next(vm => {
      vm.getCard()
    })
  },
  mounted() {
    if (judgeBigScreen()) {
      this.$refs.myTop.className = "my_top cardTopAndroid";
    }
  },
  methods: {
    ...mapActions([UPDATE_USER]),
    buyCard(item) {
      if(item.id == 5) {
        store.commit('incrementIn')
        // store.commit("tabbarOut")
        this.$router.push('/invitation')
      } else {
        if(this.CYBoxUser) {
          this.params = {
            userid: this.CYBoxUser.userID,
            token: this.CYBoxUser.loginToken,
            lecoinId: item.id,
            time: Math.floor(Date.now() / 1000),
          }
          this.buymenu = true
          this.currentMoney = item.cardAtm / 100
        } else {
          this.admin.login = true;
        }
      }
    },
    getCard() {
      if (this.CYBoxUser) {
        var lecoin = {
          userid: this.CYBoxUser.userID,
          token: this.CYBoxUser.loginToken,
          time: Math.floor(Date.now() / 1000),
        };
        lecoin.sign = ksort(lecoin, "qsdhajasfas123sq");
        askLeCoin(lecoin).then((res) => {
          var msg = res.message;
          var cardArr = []
          if (msg) {
            msg.cardInfo.map(e => {
              if(e.type == '1') {
                cardArr.push(e)
              }
              if(e.id == '5') {
                cardArr.push(e)
              }
              if(e.id == '6') {
                cardArr.push(e)
              }
              if(e.id == '7') {
                cardArr.push(e)
              }
            })
            this.cardInfo = cardArr;
          }
        });
      } else {
        this.cardInfo = [
          {
            id: '1', 
            cardPic: 'https://h5sdk.76ab.com/BoxH5/cards/card1.png'
          },
          {
            id: '2', 
            cardPic: 'https://h5sdk.76ab.com/BoxH5/cards/card2.png'
          },
          {
            id: '3', 
            cardPic: 'https://h5sdk.76ab.com/BoxH5/cards/card3.png'
          },
        ]
      }
    },
    launchPay() {
      this.params.orderType = this.radio
      this.params.sign = ksort(this.params, 'qsdhajasfas123sq')
      askBuyLeCoin(this.params).then(res => {
   
        if(res.state == '1') {
          this.buymenu = false

          try {
            window.CYEventCenter.CYPay(res.message.url);
          } catch (error) {}
          try {
            window.webkit.messageHandlers.CYPay.postMessage(res.message.url);
          } catch (error) {}

          // window.open(res.message.url)

          // 刷新卡片信息
          setTimeout(() => {
            Dialog.confirm({
              confirmButtonText: "已支付",
              cancelButtonText: "已取消",
              message: '支付完成',
            }).then(() => {

            }).catch(() => {
              
            })
          }, 500)
        } else {
          Toast(res.message)
        }
      })
    },
    onclick(id) {
      this.admin[id] = true;
    },
    clickIcon(e) {
      this.ps[e] = "text";
    },
    clickHandle(handle, transmit) {
      switch (handle) {
        case "login":
          var params = {
            appid: this.appid,
            username: this.form.username,
            password: this.form.password,
            time: Math.floor(Date.now() / 1000),
            eventType: 1,
            currentAppBuild: this.bundle,
            currentDeviceIdfa: "",
            deviceId: this.CYBoxIDFA,
          };
          var isKefu = localStorage.getItem('isKefu')
          if(isKefu) params.isKefu = localStorage.getItem('isKefu')
          params.sign = ksort(params, this.appkey);
          askLogin(params).then((res) => {
            if (res.state === "1") {
              var temp = res.message;
              temp.password = Encrypt(this.form.password);
              this.UPDATE_USER(temp);
              store.commit("loginIn");
              if(this.checked) {
                store.commit("CYBoxLists", temp)
              }
              this.admin.login = false;
              this.getCard()
              // if(this.handlegift) {
              //   this.getrestGift()
              // } else {
              //   this.clickStart(this.pageInfo.url);
              // }
              this.form = this.$options.data().form;
            } else {
              Dialog.alert({
                message: res.message,
                theme: "round-button",
              }).then(() => {});
            }
          });
          break;
        case "getcode":
          var params = {
            appid: this.appid,
            phonenum: this.form.phone,
            time: Math.floor(Date.now() / 1000),
            currentAppBuild: this.bundle,
            currentDeviceIdfa: "",
            deviceId: this.CYBoxIDFA,
          };
          params.sign = ksort(params, this.appkey);
          askGetCode(params).then((res) => {
            if (res.state === "1") {
              var i = Number(res.message);
              Toast("验证码已发送, 请查收");
              var timer = setInterval(() => {
                if (i == 0) {
                  this.$refs[transmit].style.cssText +=
                    "background:linear-gradient(90deg, #ff5659 35%, #ff0103 100%);pointer-events: auto;";
                  this.$refs[transmit].innerHTML = "获取验证码";
                  clearInterval(timer);
                } else {
                  this.$refs[transmit].style.cssText +=
                    "background:#666;pointer-events: none;";
                  this.$refs[transmit].innerHTML = i;
                }
                i--;
              }, 1000);
            } else {
              Dialog.alert({
                message: res.message,
                theme: "round-button",
              }).then(() => {});
            }
          });
          break;
        case "findpassword":
          if (this.form.password === this.form.confirmpassword) {
            var params = {
              appid: this.appid,
              phonenum: this.form.phone,
              code: this.form.code,
              password: this.form.password,
              time: Math.floor(Date.now() / 1000),
              currentDeviceIdfa: "",
              deviceId: this.CYBoxIDFA,
            };
            params.sign = ksort(params, this.appkey);
            askFindPass(params).then((res) => {
              if (res.state === "1") {
                Toast.success("密码已重置");
                this.admin.retrieve = false;
                this.form = this.$options.data().form;
              } else {
                Dialog.alert({
                  message: res.message,
                  theme: "round-button",
                }).then(() => {});
              }
            });
          } else {
            Dialog.alert({
              message: "两次密码输入不一致",
              theme: "round-button",
            }).then(() => {});
          }
          break;
        case "phoneregister":
          var params = {
            appid: this.appid,
            phonenum: this.form.phone,
            code: this.form.code,
            time: Math.floor(Date.now() / 1000),
            eventType: 2,
            currentAppBuild: this.bundle,
            currentDeviceIdfa: "",
            deviceId: this.CYBoxIDFA,
          };
          var isKefu = localStorage.getItem('isKefu')
          if(isKefu) params.isKefu = localStorage.getItem('isKefu')
          params.sign = ksort(params, this.appkey);
          askPhoneRegister(params).then((res) => {
            if (res.state === "1") {
              var temp = res.message
              temp.password = Encrypt(res.message.password)
              this.UPDATE_USER(temp);
              store.commit("loginIn");
              if(this.checked) {
                store.commit("CYBoxLists", temp)
              }
              this.admin.register = false;
              this.admin.login = false;
              Toast("登录成功");
              this.getCard()
              // if(this.handlegift) {
              //   this.getrestGift()
              // } else {
              //   this.clickStart(this.pageInfo.url);
              // }
              Dialog.alert({
                title: '您的初始密码, 请妥善保管',
                message: Decrypt(res.message.password),
                theme: "round-button",
              }).then(() => {});
              this.form = this.$options.data().form;
            } else {
              Dialog.alert({
                message: res.message,
                theme: "round-button",
              }).then(() => {});
            }
          });
          break;
        case "usernameregister":
          var params = {
            appid: this.appid,
            username: this.form.username,
            password: this.form.password,
            time: Math.floor(Date.now() / 1000),
            eventType: 2,
            currentAppBuild: this.bundle,
            currentDeviceIdfa: "",
            deviceId: this.CYBoxIDFA,
          };
          var isKefu = localStorage.getItem('isKefu')
          if(isKefu) params.isKefu = localStorage.getItem('isKefu')
          params.sign = ksort(params, this.appkey);
          askUserNameRegister(params).then((res) => {
            if (res.state === "1") {
              var temp = res.message;
              temp.password = Encrypt(this.form.password);
              this.UPDATE_USER(temp);
              store.commit("loginIn");
              if(this.checked) {
                store.commit("CYBoxLists", temp)
              }
              this.admin.register = false;
              this.admin.login = false;
              this.admin.newuser = false;
              Toast("登录成功");
              this.getCard()
              // if(this.handlegift) {
              //   this.getrestGift()
              // } else {
              //   this.clickStart(this.pageInfo.url);
              // }
              this.form = this.$options.data().form;
            } else {
              Dialog.alert({
                message: res.message,
                theme: "round-button",
              }).then(() => {});
            }
          });
          break;
        default:
          break;
      }
    }
  },
  computed: {
    ...mapGetters(["CYBoxUser", "appid", "bundle", "CYBoxIDFA", "appkey"]),
  },
};
</script>
<style lang="stylus" scoped>
@import '../components/common.styl';

.my_top {
  background: url('../assets/playedt.png') no-repeat;
  background-size: 100%;
  height: calc(78px + constant(safe-area-inset-bottom));
  height: calc(78px + env(safe-area-inset-bottom));
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  font-size: 18px;
  color: #ffffff;
  position relative
}

.my_sqk
  position absolute
  top calc(18px + constant(safe-area-inset-bottom))
  top calc(18px + env(safe-area-inset-bottom))
.card_view {
  background-color: #fff;
  position: relative;
  top: -25px;
  text-align: center;
  width: $viewWidth;
  margin: 0 auto;
  padding-top: 25px;
  border-radius: 5px;
}
.card_top
  height 120px
  background #fff
  border-radius 4px
  color #F71D1C
  display flex
  justify-content center
  align-items center
  text-align center
  margin-bottom 10px
  width 96%
  margin 0 auto 10px
.card_fuhao
  font-size 20px
.card_je
  font-size 34px
.card_center
  border-radius 4px
  background #fff
  height 196px
  width 96%
  margin 0 auto
.card_fs
  font-size 18px
  text-align center
  padding-top 16px
  padding-bottom 28px
.card_select
  margin 0 auto
  width 80%

.card_card {
  font-size: 0;
  height: 130px;
  margin-bottom: 10px;
  width: 96%;
}

.card_title {
  font-size: 16px;
  margin-bottom: 6px;
}

.card_txt {
  font-size: 12px;
  text-align: left;
  width: 96%;
  margin: 0 auto;
  padding-bottom: 10px;
}

.card_text {
  margin-bottom: 4px;
  line-height: 18px;
}

.page_pay
  width: 188px
  height: 46px;
  border-radius 23px;
  font-size 18px
  background: #20a0ff;
  background:linear-gradient(90deg,#ff5555 50%,#ff0000 100%);
  color #fff
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 28px auto 0;
</style>